<template>
<div>
  <img v-if="!settings" src="@/assets/img/spinner.gif" alt="" />
  <form v-if="settings">
    <card class="sticky">
      <content>
        <div class="row">

<div class="col mr-auto">
        <button
          v-for="lang in $available_langs_code"
          :class="[
            'btn',
            selected === lang ? 'btn-primary' : 'btn-outline-primary',
          ]"
          v-bind:key="lang"
          @click="selected = lang"
        >
          {{ lang }}
        </button>
</div>
<div class="col-auto mr-auto">
        <button
        class="btn btn-success"
        @click="save()"
        v-show="settings"
      >
        Salva Tutto
      </button>
      </div>
      </div>
      </content>
    </card>

    <card title="Generale" style="margin-top: 95px;">
      <content class="px-4">
        <fieldset>

          <div class="form-group">
              <label :for="'generale-testosx-' + selected">Suffisso titolo</label>
              <input
                :id="'generale-testosx-' + selected"
                class="form-control"
                type="text"
                v-model="settings.app[selected].seotitle"
              />
            </div>

          <div class="form-group">
            <label :for="'generale-testosx-' + selected">Titolo</label>
            <input
              :id="'generale-testosx-' + selected"
              class="form-control"
              type="text"
              v-model="settings.app[selected].title"
            />
          </div>

          <a-textarea
            title="Descrizione"
            v-model="settings.app[selected].description"
          />

          <div class="form-group row no-gutters">
            <label class="col-md-12" :for="'mosaico-image-' + selected"
              >Cover</label
            >

            <div class="col-md-4 pr-2">
              <img
                class="image"
                alt="cover"
                style="object-fit: contain"
                :src="settings.app[selected].cover"
              />
              <button
                class="btn btn-default w-100 mt-2"
                @click="pickImage('cover')"
              >
                Galleria
              </button>
            </div>

            <textarea
              rows="4"
              class="form-control col-md-8 w-100"
              :id="'mosaico-image-' + selected"
              v-model="settings.app[selected].cover"
            />
          </div>

          <div class="form-group row">
            <div class="col-12"><label>Twitter</label></div>
            <div class="col-6">
              <div class="form-group">
                <label :for="'generale-testosx-' + selected">ID</label>
                <input
                  :id="'generale-testosx-' + selected"
                  class="form-control"
                  type="text"
                  v-model="settings.app[selected]['twitter:account_id']"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label :for="'generale-testosx-' + selected">username</label>
                <input
                  :id="'generale-testosx-' + selected"
                  class="form-control"
                  type="text"
                  v-model="settings.app[selected]['twitter']"
                />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-12"><label>Facebook</label></div>
            <div class="col-6">
              <div class="form-group">
                <label :for="'generale-testosx-' + selected">Page ID</label>
                <input
                  :id="'generale-testosx-' + selected"
                  class="form-control"
                  type="text"
                  v-model="settings.app[selected]['fbpageid']"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label :for="'generale-testosx-' + selected">username</label>
                <input
                  :id="'generale-testosx-' + selected"
                  class="form-control"
                  type="text"
                  v-model="settings.app[selected]['facebook']"
                />
              </div>
            </div>
          </div>

        <div class="form-group row">
            <div class="col-12"><label>Youtube</label></div>
            <div class="col-6">
              <div class="form-group">
                <label :for="'generale-testosx-' + selected">account</label>
                <input
                  :id="'generale-testosx-' + selected"
                  class="form-control"
                  type="text"
                  v-model="settings.app[selected]['youtube']"
                />
              </div>
            </div>
          </div>

          <button class="btn btn-success" @click="save('app')">
            Salva Generale
          </button>
        </fieldset>
      </content>
    </card>

    <card title="Fascia Instagram">
      <content>
        <fieldset>
          <div class="form-group">
            <a-textarea
              title="Payoff"
              v-model="settings.instagram_home[selected].title"
            />
          </div>

          <div class="form-group">
            <label :for="'generale-newsletter-' + selected">Call to action</label>
            <input
              :id="'generale-newsletter-' + selected"
              class="form-control"
              type="text"
              v-model="settings.instagram_home[selected].button"
            />
          </div>

          <div class="form-group">
            <label :for="'insta-' + selected">Account</label>
            <input
              :id="'insta-' + selected"
              class="form-control"
              type="text"
              v-model="settings.instagram_home[selected].username"
            />
          </div>

          <button class="btn btn-success" @click="save('instagram_home')">
            Salva Instagram
          </button>
        </fieldset>
      </content>
    </card>

    <card title="Footer">
      <content>
        <fieldset>
          <div class="form-group">
            <a-textarea
              title="Payoff"
              v-model="settings.footer[selected].testo_sx"
            />
          </div>

          <div class="form-group">
            <label :for="'generale-newsletter-' + selected">Testo Newsletter</label>
            <input
              :id="'generale-newsletter-' + selected"
              class="form-control"
              type="text"
              v-model="settings.footer[selected].newsletter"
            />
          </div>

          <div class="form-group">
            <label :for="'generale-newsletter-' + selected">CTA Newsletter</label>
            <input
              :id="'generale-newsletter-cta-' + selected"
              class="form-control"
              type="text"
              v-model="settings.footer[selected].newsletter_button"
            />
          </div>
          <div class="form-group">
            <a-textarea
              title="Copyright"
              v-model="settings.footer[selected].copyright"
            />
          </div>
          <button class="btn btn-success" @click="save('app')">
            Salva Footer
          </button>
        </fieldset>
      </content>
    </card>
  </form>
  </div>
</template>
<style scoped>
.image {
  max-height: 200px;
  width: 100%;
}
</style>
<script>
import axios from "axios";
import ImagePicker from "@/components/altrama/ImagePicker.vue";
import ATextarea from "@/components/altrama/form/ATextarea.vue";
import Card from "@/components/Cards/Card.vue";
import Vue from "vue";
import AttachmentsBox from "@/components/altrama/AttachmentsBox.vue";

export default {
  data() {
    return {
      selected: "it",
      settings: null,
    };
  },

  created: async function () {
    if ((await this.$api.user.can("home-settings")) == false) {
      this.$router.replace("/not-found");
      return;
    }

    this.loadData();
  },

  mounted: function () {},
  methods: {
    pickImage(mosaicItem) {
      var ComponentClass = Vue.extend(ImagePicker);
      var instance = new ComponentClass({
        propsData: { type: "primary" },
      });
      instance.$mount();
      const self = this;
      instance.$on("close", function () {
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
      instance.$on("onimage", function (image) {
        self.settings.app[self.selected][mosaicItem] = image;
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
      this.$el.appendChild(instance.$el);
    },
    save: function (section) {
      if (section === undefined) {
        this.save("app");
        this.save("footer");
        this.save("instagram_home");
        return;
      }
      let self = this;
      axios({
        method: "post",
        url: this.$url + "operations/settings",
        headers: this.$config.axiosHeaders(),
        data: {
          section: section,
          data: self.settings[section],
        },
      })
        .then(function (response) {
          console.info(response);

          self.$notifyVue(
            "top",
            "center",
            "success",
            response.data.data.message,
            "ti-check"
          );
        })
        .catch(function (error) {
          self.$notifyVue(
            "top",
            "center",
            "success",
            error.response.data.message,
            icon
          );
        });
    },
    loadData: function () {
      let self = this;
      axios({
        method: "get",
        url: this.$url + "operations/settings",
        headers: this.$config.axiosHeaders(),
        params: {
          section: "footer,instagram_home,app", // elenco sezioni da richiamare
        },
      })
        .then(function (response) {
          if (response.status == 200) {
            self.settings = Object.assign({}, self.settings, response.data);
          }
        })
        .catch(function (error) {
          self.$processError(error);
          self.$notifyVue(
            "top",
            "center",
            "success",
            error.response.data.message,
            icon
          );
        });
    },
  }, // methods
  components: {
    "image-picker": ImagePicker,
    ATextarea,
    AttachmentsBox,
    Card,
  },
};
</script>

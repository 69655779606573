<template>
    <div>
        <div class="row">
            <!-- barra di ricerca -->
            <div class="col-auto">
                <!-- cerca -->
                <search-box v-model="searchQuery.searchTerm"></search-box>
            </div>
            <div class="col-auto">
                <label> Azioni </label>
                <div class="btn-toolbar " role="toolbar" aria-label="Bottoni azione">
                    <div class="btn-group  mr-2" role="group" aria-label="Azioni">
                        <button class="btn mb-2 btn-primary" @click="filter()">
                            <i class="ti ti-filter"></i> Filtra
                        </button>
                        <button class="btn mb-2 btn-default" @click="noFilter()">
                            <i class="ti ti-arrow-circle-up"></i> Annulla Filtri
                        </button>
                    </div>
                    <button class="btn btn-success mb-2 ml-2" @click="showUpload = !showUpload">
                           <i class="ti ti-plus"></i> Carica file
                        </button>
                </div>

            </div>
        </div>

        <div class="row p-2" v-show="showUpload">

<vue-dropzone ref="dropzone" id="dropzone" class="col-12"
      :options="dropzoneOptions"
      @vdropzone-file-added="vfileAdded"
      @vdropzone-success="vsuccess"
      @vdropzone-error="verror"


>

    </vue-dropzone>
        </div>
        <div class="card">
            <vue-good-table
                    ref="generic-table"
                    mode="remote"
                    styleClass="vgt-table striped"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    title="Lista Allegati"
                    :columns="columns"
                    :rows="rows"
                    totalRecords
                    :paginate="true"
                    :lineNumbers="false"
                    :pagination-options="{
                                enabled: true,
                                position: 'top',
                                nextLabel: 'Successivo',
                                prevLabel: 'Precedente',
                                rowsPerPageLabel: 'Righe per pagina',
                                dropdownAllowAll: false,
                                perPage: serverParams.pagination.currentPerPage,
                                perPageDropdown: serverParams.myPerPageDropdown,
                                setCurrentPage: serverParams.pagination.currentPage
                    }"
                    :sort-options="{ enabled: true, }"
                    :selectOptions="{
                          enabled: entity_delete,
                          selectOnCheckboxOnly: true,
                          selectionInfoClass: 'generic-class',
                          selectionText: 'righe selezionate',
                          clearSelectionText: 'deseleziona',
                    }"
                    :isLoading="isLoading"
            >
                <div slot="emptystate" id="emptystate" v-if="totalRecords == 0">
                    <div class="vgt-center-align vgt-text-disabled">
                        <span v-if="!isLoading">Nessun risultato disponibile</span>
                    </div>
                </div>
                <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <action-table name="Download" :path="'attachments'" :id="props.row.id" :url="props.row.url"></action-table>
          </span>

                    <span v-else>{{props.formattedRow[props.column.field]}}</span>
                </template>
                <div slot="selected-row-actions">
                    <a v-on:click="deleteAll()" v-if="entity_delete">
                        <button class="btn btn-primary">Cancella Selezionati</button>
                    </a>
                </div>
            </vue-good-table>
            <!-- chiusura componente -->
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    import StatusesBox from "@/components/altrama/StatusesBox";
    import CityCombo from "@/components/altrama/CityCombo";
    import ProvinceCombo from "@/components/altrama/ProvinceCombo";
    import CategoryCombo from "@/components/altrama/CategoryBox";
    import SearchBox from "@/components/altrama/SearchBox";
    import StatusesSpan from "@/components/altrama/StatusesSpan";
    import LangBox from "@/components/altrama/LangBox";
    import DatePicker from "@/components/altrama/DatePicker";
    import DestinationCombo from "@/components/altrama/DestinationCombo";

    let moment = require("moment");
    const tableColumns = [];
    var tableData = [];
    var self = this;

    export default {
        components: {
            SearchBox,
            CategoryCombo,
            CityCombo,
            StatusesBox,
            ProvinceCombo,
            StatusesSpan,
            LangBox,
            DatePicker,
            DestinationCombo
        },
        data() {
            return {
                showUpload: false,
                dropzoneOptions: {
                    url: this.$url + "attachments/upload",
                    thumbnailWidth: 150,
                    maxFilesize: 100,
                    headers:  this.$config.axiosHeadersUpload(),
                    addRemoveLinks: true
                },
                table1: {
                    title: "Elenco",
                    columns: [...tableColumns],
                    data: [...tableData],
                    route: "attachments"
                },
                columns: [
                    {
                        label: "ID",
                        field: "id",
                        type: "number",
                        html: false,
                        tdClass: "text-center",
                        thClass: "text-center"
                    },
                    {
                        label: "Nome",
                        field: "title",
                        sortable: false
                    },
                    {
                        label: "URL",
                        field: "url",
                        sortable: false
                    },
                    {
                        label: "AZIONI",
                        field: "action",
                        html: true,
                        tdClass: "text-center",
                        thClass: "text-center",
                        sortable: false
                    }
                ],
                entity_create: false,
                entity_delete: false,
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {},
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50]
                },
                isLoading: false,
                searchQuery: {

                }
            };
        },

        created: async function () {
            if (await this.$api.user.can("media-view") == false) {
                this.$router.replace("/not-found");
                return;
            }
            this.entity_create = await this.$api.user.can( "media-create");
            this.entity_delete = await this.$api.user.can( "media-delete");
        },

        mounted: function () {
            if (localStorage.getItem('allegati_searchQuery')) { // parametri di ricerca
                this.searchQuery = this.getSearchFilter();
            }
            if (localStorage.getItem('allegati_serverParams')) { // parametri di tabella
                this.serverParams = this.getParameterFilter();
            }
            this.updateResource();
        },

        methods: {
    vfileAdded(file) {

      // window.toastr.info('', 'Event : vdropzone-file-added')
    },
    vsuccess(file, response) {
       this.$refs["dropzone"].removeFile(file);
       this.updateResource();
      // window.toastr.success('', 'Event : vdropzone-success')
    },
    verror(file) {

      // window.toastr.error(file.upload.filename, 'Event : vdropzone-error - ' + file.status)
    },
            filter() {
                this.serverParams.pagination.currentPage = 1;
                this.updateResource();
            },

            noFilter() {
                localStorage.removeItem("allegati_searchQuery");
                localStorage.removeItem("allegati_serverParams");

                this.serverParams = {
                    columnFilters: {},
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50]
                };

                this.searchQuery = {

                };
                this.updateResource();
            },

            saveParameterFilter() {
                localStorage.setItem(
                    "allegati_searchQuery",
                    JSON.stringify(this.searchQuery)
                );
                localStorage.setItem(
                    "allegati_serverParams",
                    JSON.stringify(this.serverParams)
                );
            },

            saveSearchFilter() { // salva parametri di ricerca
                localStorage.setItem('allegati_searchQuery', JSON.stringify(this.searchQuery));
            },

            getSearchFilter() { // prende parametri di ricerca
                return JSON.parse(localStorage.getItem('allegati_searchQuery'));
            },

            getParameterFilter() { // prende parametri di tabella
                return JSON.parse(localStorage.getItem('allegati_serverParams'));
            },

            onPerPageChange(params) { // risultati per pagina
                console.log('onPerPageChange');
                console.log(params);
                this.serverParams.pagination = params;
                this.updateResource();
            },

            onPageChange(params) { // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
                console.log('onPageChange');
                console.log(params);
                this.serverParams.pagination = params;
                this.updateResource();
            },

            onSortChange(params) { // ordinamento risultati per campo
                console.log('onSortChange');
                console.log(params[0]);
                this.serverParams.sort = params[0];
                this.updateResource();
            },

            async updateResource() {
                let self = this;
                self.rows = [];
                self.isLoading = true;

                var typeSort = "+";
                if (this.serverParams.sort.type == "desc") {
                    typeSort = "-";
                }

                this.saveParameterFilter();

                await axios({
                    method: "GET",
                    url: self.$url + this.table1.route,
                    headers: this.$config.axiosHeaders(),
                    params: {
                        keywords: self.searchQuery.searchTerm,
                        orderBy: typeSort + self.serverParams.sort.field,
                        page: self.serverParams.pagination.currentPage,
                        limit: self.serverParams.pagination.currentPerPage
                    }
                }).then(function (response) {
                    self.totalRecords = response.data.meta.total;
                    self.rows = [];
                    var obj = response.data.data;
                    for (let entity of obj ) {
                        self.rows.push({
                            id: entity.id,
                            title: entity.name,
                            url: entity.url,
                        });
                    }
                }).catch(function (error) {
                    self.$processError(error);
                }).finally(() => (
                    self.isLoading = false)
                );
            },
            deleteAll: function () {
                if (confirm('Confermare cancellazione degli elementi selezionati?')) {
                    var elenco = [];
                    var selected = this.$refs['generic-table'].selectedRows;
                    for (let itm of selected) {
                        elenco.push( itm.id );
                    }
                    var self = this;
                    self.isLoading = true;

                    axios({
                        method: 'DELETE',
                        url: this.$url + this.table1.route + '/' + elenco,
                        headers: this.$config.axiosHeaders(),
                    }).then(function (response) {
                        self.$notifyVue('top', 'center', 'success', response.data.data.message, 'ti-check');
                        self.updateResource();
                    }).catch(function (error) {
                        self.$processError(error);
                    }).finally(
                        () => self.isLoading = false
                    );
                }
            },
        } // methods
    };
</script>

<template>
  <generic-list :options="options"> </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";
const baseRoute = "rent";
export default {
  components: {
    GenericList,
  },
  data() {
    return {
      options: {
        capability: baseRoute,
        actions: {
          edit: {
            routeName: "Gestione Rent",
            path: baseRoute,
          },
          new : {
             title: "Nuovo Rent",
             route : "Gestione Rent",
          }
        },
        apiGetRoute: this.$url + "rent",
        apiDeleteRoute: this.$url + "rent",
        mapper: this.itemMapper,
      },
    };
  },

  methods: {
    itemMapper(place) {
      return {
        id: place.id,
        original_id: place.original_id,
        title: (place.contents  ? place.contents.it.title: ''),
        contents: place.contents,
      };
    },
  },
};
</script>

<template>
  <div>

    <div class="row">

      <div class="col-auto">
        <label for="select-city">Comune</label>
        <select id="select-city" name="Comune" class="custom-select" v-model="filters.city" :disabled="isLoading"
          @change="onSelectCity()">
          <option :value="undefined" key="-1">-</option>
          <option v-for="city in this.data.cities" :value="city" :key="city">
            {{ city }}
          </option>
        </select>
      </div>

      <div class="col-auto">
        <label>Azioni</label>
        <div class="btn-toolbar mr-2" role="toolbar" aria-label="Bottoni azione">
          <div class="btn-group mb-2 mr-2" role="group" aria-label="Azioni">
            <button class="btn btn-primary" @click="onSubmitFilters()" :disabled="!isFiltersSelected">
              <i class="ti ti-filter"></i> Carica
            </button>
            <button class="btn btn-default" @click="onCancelFilters()" v-if="isFiltersSelected && isPopulated">
              <i class="ti ti-arrow-circle-up"></i>
              Annulla
            </button>
          </div>
        </div>
      </div>

    </div>

    <div class="row" v-show="isPopulated && !isEmpty">
      <div class="container">

        <div id="resume-stats">
          <div class="row justify-content-center mt-3">
            <div class="col col-auto text-center m-1 p-3" style="background: #832835; color: #fff;">
              <div class="title">ISTAT</div>
              <!-- <div class="subtitle">&nbsp;</div> -->
              <div class="value">{{ isLoading ? '-' : this.data.resume.istat }}</div>
            </div>
            <div class="col col-auto text-center m-1 p-3" style="background: #8D481F; color: #fff;">
              <div class="title">MIBACT</div>
              <!-- <div class="subtitle">&nbsp;</div> -->
              <div class="value">{{ isLoading ? '-' : this.data.resume.mibact }}</div>
            </div>
            <div class="col col-auto text-center m-1 p-3" style="background: #836B13; color: #fff;">
              <div class="title">TRIPADVISOR</div>
              <!-- <div class="subtitle">&nbsp;</div> -->
              <div class="value">{{ isLoading ? '-' : this.data.resume.tripadvisor }}</div>
            </div>
            <div class="col col-auto text-center m-1 p-3" style="background: #658C33; color: #fff;">
              <div class="title">WIKIPEDIA</div>
              <!-- <div class="subtitle">&nbsp;</div> -->
              <div class="value">{{ isLoading ? '-' : this.data.resume.wikipedia }}</div>
            </div>
            <div class="col col-auto text-center m-1 p-3" style="background: #01AA71; color: #fff;">
              <div class="title">TOTALE</div>
              <!-- <div class="subtitle">&nbsp;</div> -->
              <div class="value">{{ isLoading ? '-' : this.data.resume.total }}</div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <apexchart ref="attendances-chart" :height="450" :options="chart" :series="chart.series" />
          </div>
        </div>

      </div>
    </div>

    <div class="card" v-show="isPopulated">
      <vue-good-table title="Presenza online" styleClass="vgt-table striped" :columns="table.columns"
        :rows="data.attendances" :sort-options="{ enabled: false }" :isLoading="isLoading">

        <div slot="emptystate" id="emptystate" v-if="this.data.attendances.length === 0">
          <div class="vgt-center-align vgt-text-disabled">
            <span v-if="!isLoading">Nessun risultato disponibile</span>
          </div>
        </div>

        <template slot="table-row" slot-scope="props">

          <div v-if="props.column.field === 'attendances.istat'">
            <span v-if="props.row.attendances.istat === 0">
              <i class="fa fa-times" style="color: #999999;"></i>
            </span>
            <span v-else-if="props.row.attendances.istat === 1">
              <i class="fa fa-check" style="color: #832835;"></i>
            </span>
          </div>

          <div v-else-if="props.column.field === 'attendances.mibact'">
            <span v-if="props.row.attendances.mibact === 0">
              <i class="fa fa-times" style="color: #999999;"></i>
            </span>
            <span v-else-if="props.row.attendances.mibact === 1">
              <i class="fa fa-check" style="color: #832835;"></i>
            </span>
          </div>

          <div v-else-if="props.column.field === 'attendances.tripadvisor'">
            <span v-if="props.row.attendances.tripadvisor === 0">
              <i class="fa fa-times" style="color: #999999;"></i>
            </span>
            <span v-else-if="props.row.attendances.tripadvisor === 1">
              <i class="fa fa-check" style="color: #832835;"></i>
            </span>
          </div>

          <div v-else-if="props.column.field === 'attendances.wikipedia'">
            <span v-if="props.row.attendances.wikipedia === 0">
              <i class="fa fa-times" style="color: #999999;"></i>
            </span>
            <span v-else-if="props.row.attendances.wikipedia === 1">
              <i class="fa fa-check" style="color: #832835;"></i>
            </span>
          </div>

        </template>

      </vue-good-table>
    </div>

  </div>
</template>

<style lang="css">
#resume-stats .title {
  font-weight: bold;
}

#resume-stats .subtitle {
  font-size: 10px;
}

#resume-stats .value {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}
</style>

<script>
import axios from "axios";
import chartOptions from './options/chart';

export default {

  components: {

  },

  data() {
    return {

      isLoading: false,
      isPopulated: false,

      filters: {
        city: undefined
      },

      loaders: {
        attendances: false,
        cities: false
      },

      data: {
        resume: {},
        resume_by_types: {},
        attendances: [],
        cities: []
      },

      chart: {
        ...chartOptions,
      },

      table: {
        columns: [
          {
            label: "NOME",
            field: "name"
          },
          {
            label: "TIPOLOGIA",
            field: "type"
          },
          {
            label: "ISTAT",
            field: "attendances.istat",
            thClass: "text-center",
            tdClass: "text-center",
            width: '100px',
            html: true
          },
          {
            label: "MIBACT",
            field: "attendances.mibact",
            thClass: "text-center",
            tdClass: "text-center",
            width: '100px',
            html: true
          },
          {
            label: "TRIP",
            field: "attendances.tripadvisor",
            thClass: "text-center",
            tdClass: "text-center",
            width: '100px',
            html: true
          },
          {
            label: "WIKI",
            field: "attendances.wikipedia",
            thClass: "text-center",
            tdClass: "text-center",
            width: '100px',
            html: true
          },
        ],
      }

    };
  },

  computed: {
    isFiltersSelected() {
      return (
        this.filters.city !== undefined
      );
    },
    isEmpty() {
      return (this.data.attendances.length === 0);
    }
  },

  methods: {

    loadData(city) {
      if (
        city === undefined
      ) return;

      const self = this;
      self.isLoading = true;

      const qs = [
        ...(city) ? ['city=' + city] : []
      ].join('&');

      axios({
        method: "get",
        url: `${self.$url}tourism/stats/attendances?${qs}`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.data, "resume", response.data.resume);
          self.$set(self.data, "resume_by_types", response.data.resume_by_types);
          self.$set(self.data, "attendances", response.data.attendances);
          // console.log('loadData', self.data.resume, self.data.resume_by_types, self.data.attendances);
          self.updateChart();
        })
        .catch(function (error) {
          self.$processError(error);
        })
        .finally(function () {
          self.isLoading = false;
          self.isPopulated = true;
        });
    },

    loadCities() {
      const self = this;
      axios({
        method: "get",
        url: `${self.$url}tourism/stats/ui/cities`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.data, "cities", response.data.cities);
          // console.log('loadCities', self.data.cities);
        })
        .catch(function (error) {
          self.$processError(error);
        });
    },

    onSelectCity() {
      this.$emit("input", this.filters.city);
      (!this.isFiltersSelected && !this.isEmpty)
        && this.cancelResources();
    },

    onSubmitFilters() {
      this.updateResources();
    },

    onCancelFilters() {
      this.$set(this.filters, "city", undefined);
      this.cancelResources();
    },

    updateResources() {
      this.loadData(this.filters.city);
    },

    cancelResources() {
      this.isPopulated = false;
      this.$set(this.data, "resume", {});
      this.$set(this.data, "resume_by_types", {});
      this.$set(this.data, "attendances", []);
      this.cancelChart();
    },

    updateChart() {
      this.$refs['attendances-chart'].updateOptions({
        xaxis: {
          categories: Object.keys(this.data.resume_by_types).map(type => {
            return type;
          })
        },
        series: [
          {
            name: 'Istat',
            data: Object.keys(this.data.resume_by_types).map(type => {
              return this.data.resume_by_types[type].istat;
            })
          },
          {
            name: 'Mibact',
            data: Object.keys(this.data.resume_by_types).map(type => {
              return this.data.resume_by_types[type].mibact;
            })
          },
          {
            name: 'Tripadvisor',
            data: Object.keys(this.data.resume_by_types).map(type => {
              return this.data.resume_by_types[type].tripadvisor;
            })
          },
          {
            name: 'Wikipedia',
            data: Object.keys(this.data.resume_by_types).map(type => {
              return this.data.resume_by_types[type].wikipedia;
            })
          }
        ]
      });
    },

    cancelChart() {
      this.$refs['attendances-chart'].updateOptions({
        ...chartOptions
      });
    }

  },

  async created() {
    console.log('AttendancesStats::created');
  },

  async mounted() {
    console.log('AttendancesStats::mounted');
    this.loadCities();
  }

};
</script>

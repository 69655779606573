<template>
  <generic-list :options="options"> </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";
const baseRoute = "guide";
export default {
  components: {
    GenericList,
  },
  data() {
    return {
      options: {
        capability: baseRoute,
        actions: {
          edit: {
            routeName: "Gestione Guide",
            path: baseRoute,
          },
          new: {
            title: "Nuova Guida",
            route: "Gestione Guide",
          },
        },
        apiGetRoute: this.$url + baseRoute + "",
        apiDeleteRoute: this.$url + baseRoute,
      },
    };
  },
};
</script>

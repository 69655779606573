export default {

  // https://apexcharts.com/docs/options/chart/
  chart: {
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false
    },
    animations: {
      enabled: false
    }
  },

  // https://apexcharts.com/docs/options/colors/
  colors: [
    // https://mycolor.space/?hex=%23832835&sub=1
    // >>> Matching Gradient
    '#832835',
    '#8D481F',
    '#836B13',
    '#658C33',
    '#01AA71',
    '#00C2C1',
    // https://mycolor.space/?hex=%2300C2C1&sub=1
    // >>> Matching Gradient
    // '#00C2C1',
    '#00AED7',
    '#0095DE',
    '#7075CC',
    '#9F4F9E',
    '#AC2960'
  ],

  // https://apexcharts.com/docs/options/xaxis/
  xaxis: {
    type: 'categories',
    categories: [],
    labels: {
      rotate: -45,
      rotateAlways: true,
      minHeight: 0,
      maxHeight: 200
    }
  },

  // https://apexcharts.com/docs/options/yaxis/
  yaxis: {
    labels: {
      show: true
    },
    dataLabels: {
      enabled: false,
    }
  },

  // https://apexcharts.com/docs/options/datalabels/
  dataLabels: {
    enabled: false,
  },

  // https://apexcharts.com/docs/options/plotoptions/bar/
  plotOptions: {
    bar: {
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontWeight: 900
          },
          offsetY: -5,
        }
      }
    },
  },

  // https://apexcharts.com/docs/options/legend/
  legend: {
    show: false,
    position: 'top',
    offsetY: 10,
    onItemClick: {
      toggleDataSeries: false
    },
    onItemHover: {
      highlightDataSeries: false
    }
  },

  series: []

};
<template>
  <generic-list :options="options"> </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";
const baseRoute = "event";
export default {
  components: {
    GenericList,
  },
  data() {
    return {
      options: {
        columns: [
                    {
                        label: 'ID',
                        field: 'id',
                        type: 'number',
                        html: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: true,

                    },
                    {
                        label: 'TITOLO',
                        field: 'title',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        label: 'DATA INIZIO',
                        field: 'data_inizio',
                        filterable: true,
                        sortable: false,
                    },
                    {
                        label: 'DATA FINE',
                        field: 'data_fine',
                        filterable: true,
                        sortable: false,
                    },

                    {
                        label: 'STATO',
                        field: 'active',
                        filterable: false,
                        html: true,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: false,
                    },
                ],
        actions: {
          edit:{
            routeName: "Gestione Evento",
            path: baseRoute,
          },
          new : {
             title: "Nuovo evento",
             route : "Gestione Evento",
          }
        },
        apiGetRoute: this.$url + baseRoute + "",
        apiDeleteRoute: this.$url + baseRoute,
        mapper: (evento) => {debugger
                            var tempCity="";
                            if(evento.location!=null && evento.location.city!=null && evento.location.city.city!=null)
                            tempCity=evento.location.city.city;
                            return {
                                id: evento.id,
                                title: evento.contents.it.title,
                                data_inizio: this.$moment(evento.dates.from).format('DD-MM-YYYY'),//data_inizio_string,
                                data_fine: this.$moment(evento.dates.to).format('DD-MM-YYYY'),//data_fine_string,
                                contents: evento.contents,
                                active:evento.status
                            };
        }
      },
    };
  },
};
</script>

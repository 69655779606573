<template>
  <div>

    <div class="row">

      <div class="col-auto">
        <label for="select-city">Comune</label>
        <select id="select-city" name="Comune" class="custom-select" v-model="filters.city" :disabled="isLoading"
          @change="onSelectCity()">
          <option :value="undefined" key="-1">-</option>
          <option v-for="row in this.data.cities" :value="row" :key="row">
            {{ row }}
          </option>
        </select>
      </div>

      <div class="col-auto">
        <label for="select-type">Tipologia</label>
        <select id="select-type" name="Tipologia" class="custom-select" v-model="filters.type" :disabled="isLoading"
          @change="onSelectType()">
          <option :value="undefined" key="-1">-</option>
          <option v-for="row in this.data.types" :value="row" :key="row">
            {{ row }}
          </option>
        </select>
      </div>

      <div class="col-auto">
        <label>Azioni</label>
        <div class="btn-toolbar mr-2" role="toolbar" aria-label="Bottoni azione">
          <div class="btn-group mb-2 mr-2" role="group" aria-label="Azioni">
            <button class="btn btn-primary" @click="onSubmitFilters()" :disabled="!isFiltersSelected">
              <i class="ti ti-filter"></i> Carica
            </button>
            <button class="btn btn-default" @click="onCancelFilters()" v-if="isFiltersSelected && isPopulated">
              <i class="ti ti-arrow-circle-up"></i>
              Annulla
            </button>
          </div>
        </div>
      </div>

    </div>

    <div class="row" v-show="isPopulated && !isEmpty">
      <div class="container">

        <div id="heatmap-stats-legend">
          <div class="row justify-content-center">
            <div class="col col-auto text-center p-3 m-3 border" style=" background: #fff;">

              <div style="display: flex; justify-content: center; align-items: center;"
                v-if="this.data.weights.steps && this.data.weights.steps.length > 0">
                <div v-for="(step, s) in this.data.weights.steps" :key="s">
                  <label class="mt-0 mb-2" style="font-size: 16px; font-weight: 900;">{{ step }}</label>
                  <div class="p-3" :style="heatmapStylizeStepLegend(s)">&nbsp;</div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="card" v-show="isPopulated">

      <div id="heatmap-stats" @mousedown="heatmapMouseDownHandler">
        <vue-good-table title="Ricerche online" styleClass="vgt-table" :columns="table.columns" :rows="data.searches"
          :sort-options="{ enabled: false }" :isLoading="isLoading">

          <div slot="emptystate" id="emptystate" v-if="this.data.searches.length === 0">
            <div class="vgt-center-align vgt-text-disabled">
              <span v-if="!isLoading">Nessun risultato disponibile</span>
            </div>
          </div>

          <template slot="table-row" slot-scope="props">

            <div v-if="props.column.field === 'name'">
              <div style="padding: .75em .75em .75em .75em; " class="text-overflow-name" :title="props.row.name">
                {{ props.row.name }}
              </div>
            </div>

            <div v-else>
              <div :style="heatmapStylizeSearchRow(props)" :title="heatmapSanitizeSearchTitle(props)">
                <div class="text-overflow-search-value">
                  {{ props.row.searches[props.column.field.replace("searches.", "")].v }}
                </div>
                &nbsp; <!-- css:important! -->
              </div>
            </div>

          </template>

        </vue-good-table>
      </div>

    </div>

  </div>
</template>

<style lang="css">
#heatmap-stats table th:first-child,
#heatmap-stats table td:first-child {
  position: sticky;
  left: 0;
  z-index: 9999;
}

/* #heatmap-stats table th:last-child,
#heatmap-stats table td:last-child {
  position: sticky;
  right: 0;
  z-index: 9999;
} */

#heatmap-stats table td {
  border-right: 2px solid #DCDFE6;
}

#heatmap-stats table td:last-child {
  border-right: 0px;
}

#heatmap-stats table td:first-child {
  overflow: hidden;
}

/* #heatmap-stats table td:last-child {
  overflow: hidden;
} */

#heatmap-stats .text-overflow-cell {
  overflow: hidden;
  max-width: 250px;
}

#heatmap-stats .text-overflow-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff;
}

#heatmap-stats .text-overflow-search-value {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
}
</style>

<script>
import axios from "axios";

export default {

  components: {

  },

  data() {
    return {

      isLoading: false,
      isPopulated: false,

      filters: {
        city: undefined,
        type: undefined
      },

      loaders: {
        searches: false,
        cities: false,
        types: false
      },

      data: {
        weights: {},
        searches: [],
        cities: [],
        types: []
      },

      table: {
        columns: [
          {
            label: "",
            field: "name",
            thClass: "p-2",
            tdClass: "p-0 m-0 text-overflow-cell"
          },
          {
            label: "IT",
            field: "searches.it",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "EN",
            field: "searches.en",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "FR",
            field: "searches.fr",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "ES",
            field: "searches.es",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "DE",
            field: "searches.de",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "NL",
            field: "searches.nl",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "PT",
            field: "searches.pt",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "RU",
            field: "searches.ru",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "ZH",
            field: "searches.zh-TW",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "TW",
            field: "searches.zh-TW",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "JA",
            field: "searches.ja",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "PL",
            field: "searches.pl",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "EL",
            field: "searches.el",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "SV",
            field: "searches.sv",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "DA",
            field: "searches.da",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "HE",
            field: "searches.he",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "TR",
            field: "searches.tr",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "NO",
            field: "searches.no",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "HU",
            field: "searches.hu",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "CS",
            field: "searches.cs",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "KO",
            field: "searches.ko",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "FI",
            field: "searches.fi",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "SK",
            field: "searches.sk",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          },
          {
            label: "SR",
            field: "searches.sr",
            thClass: "text-center p-2",
            tdClass: "text-center p-0 m-0",
            width: "45px",
            html: true
          }
        ],
        position: {
          top: 0,
          left: 0,
          x: 0,
          y: 0
        }
      }

    };
  },

  computed: {
    isFiltersSelected() {
      return (
        this.filters.city !== undefined
        || this.filters.type !== undefined
      );
    },
    isEmpty() {
      return (this.data.searches.length === 0);
    }
  },

  methods: {

    loadData(city, type) {
      if (
        city === undefined
        && type === undefined
      ) return;

      const self = this;
      self.isLoading = true;

      const qs = [
        ...(city) ? ['city=' + city] : [],
        ...(type) ? ['type=' + type] : []
      ].join('&');

      axios({
        method: "get",
        url: `${self.$url}tourism/stats/searches?${qs}`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.data, "weights", response.data.weights);
          self.$set(self.data, "searches", response.data.searches);
          console.log('loadData', self.data.weights, self.data.searches);
        })
        .catch(function (error) {
          self.$processError(error);
        })
        .finally(function () {
          self.isLoading = false;
          self.isPopulated = true;
        });
    },

    loadCities() {
      const self = this;
      axios({
        method: "get",
        url: `${self.$url}tourism/stats/ui/cities`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.data, "cities", response.data.cities);
          console.log('loadCities', self.data.cities);
        })
        .catch(function (error) {
          self.$processError(error);
        });
    },

    loadTypes() {
      const self = this;
      axios({
        method: "get",
        url: `${self.$url}tourism/stats/ui/types`,
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.$set(self.data, "types", response.data.types);
          console.log('loadTypes', self.data.types);
        })
        .catch(function (error) {
          self.$processError(error);
        });
    },

    onSelectCity() {
      this.$emit("input", this.filters.city);
      (!this.isFiltersSelected && !this.isEmpty)
        && this.cancelResources();
    },

    onSelectType() {
      this.$emit("input", this.filters.type);
      (!this.isFiltersSelected && !this.isEmpty)
        && this.cancelResources();
    },

    onSubmitFilters() {
      this.updateResources();
    },

    onCancelFilters() {
      this.$set(this.filters, "city", undefined);
      this.$set(this.filters, "type", undefined);
      this.cancelResources();
    },

    updateResources() {
      this.loadData(this.filters.city, this.filters.type);
    },

    cancelResources() {
      this.isPopulated = false;
      this.$set(this.data, "resume", {});
      this.$set(this.data, "resume_by_types", {});
      this.$set(this.data, "searches", []);
    },

    heatmapStylizeStepLegend(step) {
      const style = ""
        + " width: 75px;"
        + " background-color: rgb(131, 40, 53, " + (1.0 - (step * 0.1)) + ");"
      return style;
    },

    heatmapStylizeSearchRow(props) {
      const field = props.column.field.replace("searches.", "");
      const opacity = parseFloat(props.row.searches[field].w);
      const style = ""
        + " display: flex;"
        + " justify-content: center;"
        + " align-items: center;"
        + " padding: .75em .75em .75em .75em;"
        + " background-color: rgb(131, 40, 53, " + opacity + ");"
        + ((opacity >= 0.5) && " color: #fff;");
      return style;
    },

    heatmapSanitizeSearchTitle(props) {
      const field = props.column.field.replace("searches.", "");
      if (field === 'zh-TW') {
        return "TW" + ": " + props.row.searches[field].v;
      } else {
        return field.toUpperCase() + ": " + props.row.searches[field].v;
      }
    },

    heatmapMouseDownHandler(e) {

      // scrollable table element
      const table = document
        .getElementById("heatmap-stats")
        .getElementsByClassName("vgt-responsive")[0];

      // change the cursor and prevent user from selecting the text
      table.getElementsByTagName("tr").forEach(tr => {
        tr.style.cursor = 'grabbing';
        tr.style.userSelect = 'none';
      });

      // set current table position
      this.$set(this.table, "position", {
        // current scroll
        left: table.scrollLeft,
        top: table.scrollTop,
        // current mouse position
        x: e.clientX,
        y: e.clientY,
      });

      // add mouse handlers
      document.addEventListener('mousemove', this.heatmapMouseMoveHandler);
      document.addEventListener('mouseup', this.heatmapMouseUpHandler);

    },

    heatmapMouseMoveHandler(e) {

      // how far the mouse has been moved
      const dx = e.clientX - this.table.position.x;
      const dy = e.clientY - this.table.position.y;

      // scrollable table element
      const table = document
        .getElementById("heatmap-stats")
        .getElementsByClassName("vgt-responsive")[0];

      // scroll the element
      table.scrollTop = this.table.position.top - dy;
      table.scrollLeft = this.table.position.left - dx;

      // // let direction = "";
      // // // if (e.movementY > 0 && e.movementX == 0) {
      // // //   direction = "down";
      // // // } else if (e.movementY < 0 && e.movementX == 0) {
      // // //   direction = "up";
      // // // } else if (e.movementX > 0 && e.movementY == 0) {
      // // //   direction = "right";
      // // // } else if (e.movementX < 0 && e.movementY == 0) {
      // // //   direction = "left";
      // // // }
      // // if((e.movementY < 0 && e.movementX == 0) || (e.movementY > 0 && e.movementX == 0)) {
      // //   direction = "up & down";
      // // } else if((e.movementX < 0 && e.movementY == 0) || (e.movementX > 0 && e.movementY == 0)) {
      // //   direction = "left & right";
      // // }
      // const direction = ((e.movementY < 0 && e.movementX == 0) || (e.movementY > 0 && e.movementX == 0))
      // ? "up & down" : ((e.movementX < 0 && e.movementY == 0) || (e.movementX > 0 && e.movementY == 0))
      // ? "left & right" : undefined;
      // if(direction) {
      //   console.log("direction", direction);
      // }

    },

    heatmapMouseUpHandler() {

      // remove mouse handlers
      document.removeEventListener('mousemove', this.heatmapMouseMoveHandler);
      document.removeEventListener('mouseup', this.heatmapMouseUpHandler);

      // scrollable table element
      const table = document
        .getElementById("heatmap-stats")
        .getElementsByClassName("vgt-responsive")[0];

      // cleanup style
      table.getElementsByTagName("tr").forEach(tr => {
        tr.style.removeProperty('cursor');
        tr.style.removeProperty('user-select');
      });

    }

  },

  async created() {
    console.log('SearchesStats::created');
  },

  async mounted() {
    console.log('SearchesStats::mounted');
    this.loadCities();
    this.loadTypes();
  }

};
</script>

<template>
  <generic-list :options="options"> </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";

const baseRoute = "eat";
export default {
  components: {
    GenericList,
  },
  data() {
    return {
      options: {
        capability: baseRoute,
        actions: {
          edit: {
            routeName: "Gestione Ristoranti",
            path: baseRoute,
          },
          new: {
            title: "Nuovo Ristorante",
            route: "Gestione Ristoranti",
          },
        },
        columns: [
          {
            label: "ID",
            field: "id",
            type: "number",
            html: false,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: true,
          },
          {
            label: "TITOLO",
            field: "title",
            filterable: true,
            sortable: false,
          },
          {
            label: "STATO",
            field: "status",
            filterable: false,
            html: true,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: false,
          },
        ],

        apiGetRoute: this.$url + "eat",
        apiDeleteRoute: this.$url + "eat",
        mapper: this.itemMapper,
      },
    };
  },

  methods: {
    itemMapper(item) {
      return {
        id: item.id,
        title: item.contents["it"].title,
        contents: item.contents,
      };
    },
  },
};
</script>

<template>
  <div>
    <!-- Test loading -->
    <div class="vgt-loading vgt-center-align" v-if="isLoaded">
      <span class="vgt-loading__content">Loading...</span>
    </div>

    <!-- Loader for post request -->
    <div class="vgt-loading vgt-center-align loader" v-if="loadingRequest">
      <span class="vgt-loading__content">Loading...</span>
    </div>

    <form>
      <div
        v-for="(value, lang) in settings"
        v-bind:key="'tab' + lang"
        v-show="selected == lang"
      >
        <card>
          <div class="card-header">
            <button
              class="btn btn-default"
              v-for="lang in Object.keys(settings)"
              v-bind:key="lang"
              @click="selected = lang"
            >
              {{ lang }}
            </button>
          </div>

          <div class="form-group">
            <label :for="'events-id-' + lang">Eventi in evidenza</label>
            <input
              :id="'events-id-' + lang"
              class="form-control"
              type="text"
              v-model="value.events"
            />
            <small :id="'events-id-Help-' + lang" class="form-text text-muted"
              >Lista di id da visualizzare in home</small
            >
          </div>

          <div class="form-group">
            <label :for="'events-id-' + lang">News in evidenza</label>
            <input
              :id="'events-id-' + lang"
              class="form-control"
              type="text"
              v-model="value.news"
            />

            <small :id="'events-id-Help-' + lang" class="form-text text-muted"
              >Lista di id da visualizzare in home</small
            >
          </div>
        </card>
        <!-- <label>Mosaico Homepage</label> -->
        <card title="Mosaico homepage" :collapse="false">
          <div
            v-for="(mosaico, index) in value.mosaico"
            v-bind:key="'mosaico' + index"
          >
            <div class="form-group row">
              <div class="col-md-3">
                <img class="image" :src="mosaico.image" />
              </div>
              <div class="col-md-9">
                <textarea
                  rows="4"
                  :id="'mosaico-image-' + lang + index"
                  class="form-control"
                  type="text"
                  v-model="mosaico.image"
                />
                <button
                  class="btn btn-default mtBtns"
                  @click="pickImage(mosaico)"
                >
                  Galleria
                </button>
              </div>
            </div>

            <div class="form-group">
              <label :for="'mosaico-title-' + lang + index">Titolo</label>
              <input
                :id="'mosaico-title-' + lang + index"
                class="form-control"
                type="text"
                v-model="mosaico.title"
              />
            </div>

            <div class="form-group">
              <label :for="'mosaico-label-' + lang + index">Descrizione</label>
              <input
                :id="'mosaico-label-' + lang + index"
                class="form-control"
                type="text"
                v-model="mosaico.label"
              />
            </div>

            <div class="form-group">
              <label :for="'mosaico-url-' + lang + index">Url</label>
              <input
                :id="'mosaico-url-' + lang + index"
                class="form-control"
                type="text"
                v-model="mosaico.url"
              />
            </div>
            <div class="form-group">
            <div class="custom-control custom-checkbox form-check">

              <input
                :id="'mosaico-mobile-hide-' + lang + index"
                class="custom-control-input form-check-input"
                type="checkbox"
                v-model="mosaico.mobileHide"
              />
              <label class="custom-control-label form-check-label" :for="'mosaico-mobile-hide-' + lang + index">Nascondi da mobile</label>
</div>
            </div>
            <hr />
          </div>
        </card>

        <!-- Editor video youtube -->
        <card title="Sezione Video" :collapse="false">
          <div class="card-body">
            <!-- Card  -->
            <!-- {{ video }} -->
            <div
              class="row g-0"
              v-for="(video, index) in value.video"
              v-bind:key="index"
              :id="'video-image-' + lang + index"
            >
              <div class="col-md-3">
                <img
                  v-show="video.image"
                  class="image"
                  :src="video.image"
                  alt="Seleziona immagine da galleria"
                />
              </div>

              <div class="col-md-9">
                <div class="form-group">
                  <label>Titolo video</label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="video.titolo"
                  />
                </div>

                <div class="form-group">
                  <label>Url video</label>
                  <input class="form-control" type="text" v-model="video.url" />

                  <label>Copertina video</label>
                  <textarea
                    rows="4"
                    class="form-control"
                    type="text"
                    v-model="video.image"
                  />
                  <button
                    class="btn btn-default mtBtns"
                    @click="pickImage(video)"
                  >
                    Galleria
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr />
        </card>
      </div>

      <card>
        <button
          class="btn btn-success"
          style="margin-left: 1%"
          @click="save()"
          v-show="!isLoaded"
        >
          Salva Impostazioni
        </button>
      </card>
    </form>
  </div>
</template>
<style scoped>
.image {
  max-height: 200px;
  width: 100%;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  margin-left: 50%;
  margin-top: 20%;
  width: 3rem;
  height: 3rem;

  z-index: 9999;
}

.mtBtns {
  margin-top: 1%;
}
</style>
<script>
import axios from "axios";
import ImagePicker from "@/components/altrama/ImagePicker.vue";
import Card from "@/components/Cards/Card.vue";
import Vue from "vue";
import AttachmentsBox from "@/components/altrama/AttachmentsBox.vue";
export default {
  data() {
    return {
      isLoaded: false,
      loadingRequest: false,

      selected: "it",
      settings: {
        it: {
          mosaico: [],
          events: "",
          video: [],
        },
        en: {
          mosaico: [],
          events: "",
          video: [],
        },
      },
    };
  },

  created: async function () {


    if (await this.$api.user.can("home-settings") == false) {
      this.$router.replace("/not-found");
      return;
    }

    this.loadData();
  },

  mounted: function () {},
  methods: {
    pickImage(mosaicItem) {
      var ComponentClass = Vue.extend(ImagePicker);
      var instance = new ComponentClass({
        propsData: { type: "primary" },
      });
      instance.$mount();
      instance.$on("close", function () {
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
      instance.$on("onimage", function (image) {
        mosaicItem.image = image;
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
      this.$el.appendChild(instance.$el);
    },
    save: function () {
      let self = this;

      self.loadingRequest = true;

      axios({
        method: "post",
        url: this.$url + "operations/settings",
        headers: this.$config.axiosHeaders(),
        data: {
          section: "home",
          data: self.settings,
        },
      })
        .then(function (response) {
          console.log("Response: ", response);

          self.loadingRequest = false;

          self.$notifyVue(
            "top",
            "center",
            "success",
            response.data.data.message,
            "ti-check"
          );
        })
        .catch(function (error) {
          self.$notifyVue(
            "top",
            "center",
            "success",
            error.response.data.message,
            icon
          );
        });
    },
    loadData: function () {
      let self = this;
      axios({
        method: "get",
        url: this.$url + "operations/settings",
        headers: this.$config.axiosHeaders(),
        params: {
          section: "home",
        },
      })
        .then(function (response) {
          console.info("Response get: ", response);

          if (response.status == 200) {
            let settings = response.data;

            settings.it.video = settings.it.video || [];
            while (settings.it.video.length < 5) {
              settings.it.video.push({
                image: "",
                titolo: "",
                url: "",
              });
            }

            settings.en.video = settings.en.video || [];
            while (settings.en.video.length < 5) {
              settings.en.video.push({
                image: "",
                titolo: "",
                url: "",
              });
            }
            self.settings = Object.assign({}, self.settings, settings);
          }

          self.isLoaded = false;
        })
        .catch(function (error) {
          self.$processError(error);
          self.$notifyVue(
            "top",
            "center",
            "success",
            error.message,
            "ti-close"
          );
        });

      this.isLoaded = true;
    },

    /* Test delete video */
    // ! SOLO X TEST
    deleteVideo(event, videoImage) {
      event.preventDefault();

      console.log("videoImage :", videoImage);
      //console.log(this.settings.it.video);
      let mappingVideoIT = this.settings.it.video.map((item) => {
        //console.log(item.image);
        if (item.image === videoImage) {
          //console.log("Uguali");
          item.image = "";
          item.titoloVideo = "";
          item.urlVideo = "";
        }
      });

      let mappingVideoEN = this.settings.en.video.map((item) => {
        //console.log(item.image);
        if (item.image === videoImage) {
          //console.log("Uguali");
          item.image = "";
          item.titoloVideo = "";
          item.urlVideo = "";
        }
      });

      mappingVideoIT;
      mappingVideoEN;
      //this.settings.it.video = [{}, {}, {}, {}, {}];
    },
    // !------------------------------------------------------------------------------
  }, // methods

  components: {
    "image-picker": ImagePicker,
    Card,
    AttachmentsBox
  },
};
</script>

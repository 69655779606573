<template>
  <generic-list :options="options"> </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";
const baseRoute = "stay";

export default {
  components: {
    GenericList,
  },
  data() {
    return {
      options: {
           capability: baseRoute,
        actions: {
          edit:{
            routeName: "Gestione Hotel",
            path: "stay",
          },
          new : {
             title: "Nuovo Hotel",
             route : "Gestione Hotel",
          }
        },
        columns: [
          {
            label: "ID",
            field: "id",
            type: "number",
            html: false,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: true,
          },
          {
            label: "TITOLO",
            field: "title",
            filterable: true,
            sortable: false,
          },

          {
            label: "STATO",
            field: "status",
            filterable: false,
            html: true,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: false,
          }
        ],
        apiGetRoute: this.$url + "stay",
        apiDeleteRoute: this.$url + "stay",
        mapper: this.itemMapper,
      },
    };
  },

  methods: {
    itemMapper(place) {
      return {
        id: place.id,
        original_id: place.original_id,
        title: (place.contents  ? place.contents.it.title: ''),
        contents: place.contents,
      };
    },
  },
};
</script>

<template>
  <generic-list :options="options" noStatus="true"> </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";
const baseRoute = "templates";

export default {
  components: {
    GenericList,
  },
  data() {
    return {
      options: {
           capability: baseRoute,
        actions: {
          edit:{
            routeName: "Gestione Template",
            path: "templates",
          },
          new : {
             title: "Nuovo Template",
             route : "Gestione Template",
          }
        },
        columns: [
          {
            label: "id",
            field: "id",
            type: "number",
            html: false,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: true,
          },
          {
            label: "TITOLO",
            field: "title",
            filterable: true,
            sortable: false,
          }
        ],
        apiGetRoute: this.$url + "templates",
        apiDeleteRoute: this.$url + "templates",
        mapper: this.itemMapper,
      },
    };
  },

  methods: {
    itemMapper(place) {
      return {
        id: place.id,
        title: place.name,
      };
    },
  },
};
</script>

<template>
  <generic-list :options="options"> </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";
const baseRoute = "station";


export default {
  components: {
    GenericList,
  },
  data() {
    return {
      options: {
        capability: baseRoute,
        actions: {
           edit:{
            routeName: "Gestione Station",
            path: baseRoute,
          },
          new: {
            title: "Nuova stazione",
            route: "Gestione Station",
          },
        },
        columns: [
          {
            label: "ID",
            field: "id",
            type: "number",
            html: false,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: true,
          },
          {
            label: "TITOLO",
            field: "title",
            filterable: true,
            sortable: false,
          },

          {
            label: "COMUNE",
            field: "comune",
            filterable: true,
            sortable: false,
          },
        ],

        apiGetRoute: this.$url + "station",
        apiDeleteRoute: this.$url + "station",
        mapper: this.itemMapper,
      },
    };
  },

  methods: {
    itemMapper(place) {
      return {
        id: place.id,
        title: place.contents ? place.contents.it.title : "",
        comune: place.location.city.city || "",
        contents: place.contents,
      };
    },
  },
};
</script>
